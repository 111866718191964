// printf 'import { ReactComponent as Person%d } from "./people/%05d.svg";\n' $(seq 0 0.5 73.5)
import { ReactComponent as Person0 } from "./people/00000.svg";
import { ReactComponent as Person1 } from "./people/00001.svg";
import { ReactComponent as Person2 } from "./people/00002.svg";
import { ReactComponent as Person3 } from "./people/00003.svg";
import { ReactComponent as Person4 } from "./people/00004.svg";
import { ReactComponent as Person5 } from "./people/00005.svg";
import { ReactComponent as Person6 } from "./people/00006.svg";
import { ReactComponent as Person7 } from "./people/00007.svg";
import { ReactComponent as Person8 } from "./people/00008.svg";
import { ReactComponent as Person9 } from "./people/00009.svg";
import { ReactComponent as Person10 } from "./people/00010.svg";
import { ReactComponent as Person11 } from "./people/00011.svg";
import { ReactComponent as Person12 } from "./people/00012.svg";
import { ReactComponent as Person13 } from "./people/00013.svg";
import { ReactComponent as Person14 } from "./people/00014.svg";
import { ReactComponent as Person15 } from "./people/00015.svg";
import { ReactComponent as Person16 } from "./people/00016.svg";
import { ReactComponent as Person17 } from "./people/00017.svg";
import { ReactComponent as Person18 } from "./people/00018.svg";
import { ReactComponent as Person19 } from "./people/00019.svg";
import { ReactComponent as Person20 } from "./people/00020.svg";
import { ReactComponent as Person21 } from "./people/00021.svg";
import { ReactComponent as Person22 } from "./people/00022.svg";
import { ReactComponent as Person23 } from "./people/00023.svg";
import { ReactComponent as Person24 } from "./people/00024.svg";
import { ReactComponent as Person25 } from "./people/00025.svg";
import { ReactComponent as Person26 } from "./people/00026.svg";
import { ReactComponent as Person27 } from "./people/00027.svg";
import { ReactComponent as Person28 } from "./people/00028.svg";
import { ReactComponent as Person29 } from "./people/00029.svg";
import { ReactComponent as Person30 } from "./people/00030.svg";
import { ReactComponent as Person31 } from "./people/00031.svg";
import { ReactComponent as Person32 } from "./people/00032.svg";
import { ReactComponent as Person33 } from "./people/00033.svg";
import { ReactComponent as Person34 } from "./people/00034.svg";
import { ReactComponent as Person35 } from "./people/00035.svg";
import { ReactComponent as Person36 } from "./people/00036.svg";
import { ReactComponent as Person37 } from "./people/00037.svg";
import { ReactComponent as Person38 } from "./people/00038.svg";
import { ReactComponent as Person39 } from "./people/00039.svg";
import { ReactComponent as Person40 } from "./people/00040.svg";
import { ReactComponent as Person41 } from "./people/00041.svg";
import { ReactComponent as Person42 } from "./people/00042.svg";
import { ReactComponent as Person43 } from "./people/00043.svg";
import { ReactComponent as Person44 } from "./people/00044.svg";
import { ReactComponent as Person45 } from "./people/00045.svg";
import { ReactComponent as Person46 } from "./people/00046.svg";
import { ReactComponent as Person47 } from "./people/00047.svg";
import { ReactComponent as Person48 } from "./people/00048.svg";
import { ReactComponent as Person49 } from "./people/00049.svg";
import { ReactComponent as Person50 } from "./people/00050.svg";
import { ReactComponent as Person51 } from "./people/00051.svg";
import { ReactComponent as Person52 } from "./people/00052.svg";
import { ReactComponent as Person53 } from "./people/00053.svg";
import { ReactComponent as Person54 } from "./people/00054.svg";
import { ReactComponent as Person55 } from "./people/00055.svg";
import { ReactComponent as Person56 } from "./people/00056.svg";
import { ReactComponent as Person57 } from "./people/00057.svg";
import { ReactComponent as Person58 } from "./people/00058.svg";
import { ReactComponent as Person59 } from "./people/00059.svg";
import { ReactComponent as Person60 } from "./people/00060.svg";
import { ReactComponent as Person61 } from "./people/00061.svg";
import { ReactComponent as Person62 } from "./people/00062.svg";
import { ReactComponent as Person63 } from "./people/00063.svg";
import { ReactComponent as Person64 } from "./people/00064.svg";
import { ReactComponent as Person65 } from "./people/00065.svg";
import { ReactComponent as Person66 } from "./people/00066.svg";
import { ReactComponent as Person67 } from "./people/00067.svg";
import { ReactComponent as Person68 } from "./people/00068.svg";
import { ReactComponent as Person69 } from "./people/00069.svg";
import { ReactComponent as Person70 } from "./people/00070.svg";
import { ReactComponent as Person71 } from "./people/00071.svg";
import { ReactComponent as Person72 } from "./people/00072.svg";
import { ReactComponent as Person73 } from "./people/00073.svg";

// printf '  {svg: Person%d, scale:1},\n' $(seq 0 1 73)
export const svgs = [
  { svg: Person0, scale: 1 },
  { svg: Person1, scale: 1 },
  { svg: Person2, scale: 1 },
  { svg: Person3, scale: 0.7 },
  { svg: Person4, scale: 0.7 },
  { svg: Person5, scale: 0.7 },
  { svg: Person6, scale: 0.7 },
  { svg: Person7, scale: 1 },
  { svg: Person8, scale: 0.7 },
  { svg: Person9, scale: 0.7 },
  { svg: Person10, scale: 1 },
  { svg: Person11, scale: 1 },
  { svg: Person12, scale: 1 },
  { svg: Person13, scale: 1 },
  { svg: Person14, scale: 1 },
  { svg: Person15, scale: 1 },
  { svg: Person16, scale: 1 },
  { svg: Person17, scale: 1 },
  { svg: Person18, scale: 1 },
  { svg: Person19, scale: 1 },
  { svg: Person20, scale: 1 },
  { svg: Person21, scale: 1 },
  { svg: Person22, scale: 1 },
  { svg: Person23, scale: 1 },
  { svg: Person24, scale: 0.7 },
  { svg: Person25, scale: 0.7 },
  { svg: Person26, scale: 1 },
  { svg: Person27, scale: 1 },
  { svg: Person28, scale: 1 },
  { svg: Person29, scale: 1 },
  { svg: Person30, scale: 1 },
  { svg: Person31, scale: 1 },
  { svg: Person32, scale: 1 },
  { svg: Person33, scale: 1 },
  { svg: Person34, scale: 1 },
  { svg: Person35, scale: 1 },
  { svg: Person36, scale: 1 },
  { svg: Person37, scale: 1 },
  { svg: Person38, scale: 1 },
  { svg: Person39, scale: 1 },
  { svg: Person40, scale: 1 },
  { svg: Person41, scale: 1 },
  { svg: Person42, scale: 1 },
  { svg: Person43, scale: 1 },
  { svg: Person44, scale: 1 },
  { svg: Person45, scale: 1.2 },
  { svg: Person46, scale: 1 },
  { svg: Person47, scale: 1.2 },
  { svg: Person48, scale: 1.2 },
  { svg: Person49, scale: 1 },
  { svg: Person50, scale: 1 },
  { svg: Person51, scale: 1 },
  { svg: Person52, scale: 1 },
  { svg: Person53, scale: 1 },
  { svg: Person54, scale: 1 },
  { svg: Person55, scale: 1 },
  { svg: Person56, scale: 1 },
  { svg: Person57, scale: 1 },
  { svg: Person58, scale: 1 },
  { svg: Person59, scale: 1 },
  { svg: Person60, scale: 1 },
  { svg: Person61, scale: 1 },
  { svg: Person62, scale: 1 },
  { svg: Person63, scale: 1 },
  { svg: Person64, scale: 1 },
  { svg: Person65, scale: 1 },
  { svg: Person66, scale: 1 },
  { svg: Person67, scale: 1 },
  { svg: Person68, scale: 1 },
  { svg: Person69, scale: 1 },
  { svg: Person70, scale: 1 },
  { svg: Person71, scale: 1 },
  { svg: Person72, scale: 1 },
  { svg: Person73, scale: 1 }
];

export default {
  svgs
};
