// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;

import "./styles.css";
import { MapContainer, ImageOverlay } from "react-leaflet";
import { CRS } from "leaflet";
import { renderMapAsPng } from "./svgs";
import React, { useState, useEffect } from "react";
import Waldo from "./waldo.svg";

var bounds = [
  [0, 0],
  [300, 300] //height, width
];

export default function App() {
  const [map, setMap] = useState(null);
  const [pngUrl, setPngUrl] = useState(null);

  useEffect(() => {
    if (map == null) return;
    console.log("calling fitBounds()");
    map.fitBounds(bounds);
  }, [map]);

  function refreshMap() {
    renderMapAsPng().then((pngUrl) => {
      setPngUrl(pngUrl);
    });
  }

  useEffect(() => {
    if (pngUrl != null) return;
    console.log("fetching png x", pngUrl);
    refreshMap();
  }, [pngUrl]);

  return (
    <div className="App">
      <div
        className="header"
        style={{ backgroundColor: "#333", padding: 0, color: "#AAA" }}
      >
        <img src={Waldo} alt="waldo" height="50" style={{ float: "left" }} />
        <div style={{ paddingBottom: 5, float: "left", marginTop: 18 }}>
          Where's Wally?
        </div>
        <button onClick={refreshMap} style={{ float: "right", marginTop: 16 }}>
          Refresh
        </button>
        {!pngUrl && (
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              zIndex: 1002,
              backgroundColor: "white",
              padding: 10,
              marginLeft: -45,
              marginTop: -19,
              color: "#333"
            }}
          >
            Loading...
          </div>
        )}
      </div>
      <div className="map-wrapper">
        <MapContainer zoom={0} maxZoom={4} crs={CRS.Simple} ref={setMap}>
          {pngUrl && <ImageOverlay url={pngUrl} bounds={bounds} />}
        </MapContainer>
      </div>
      <div
        className="footer"
        style={{ backgroundColor: "#333", color: "#999", padding: 8 }}
      >
        made with &lt;3
      </div>
    </div>
  );
}
