import ReactDOMServer from "react-dom/server";
import { Canvg, presets } from "canvg";

import { svgs } from "./svgs_index";
import { ReactComponent as Waldo } from "./waldo.svg";

const preset = presets.offscreen();

function randomFromInterval(min, max) {
  return Math.random() * (max - min + 1) + min;
}

export function renderMapAsSvg() {
  let peopleDefs = (
    <defs>
      {svgs.map((PersonSvg, i) => (
        <PersonSvg.svg
          id={"people" + i}
          key={"people" + i}
          height={10 * PersonSvg.scale + "%"}
        />
      ))}
      <Waldo id="waldo" key="waldo" height="10%" />
    </defs>
  );

  let people = [];
  for (let i = 0; i < 500; i++) {
    let posX = randomFromInterval(-50, 50);
    let posY = randomFromInterval(0, 100);
    people.push([posX, posY]);
  }

  let peopleFinal = [];
  people.forEach((element, i) => {
    const randomSvg = Math.floor(Math.random() * svgs.length);
    let posX = element[0];
    let posY = element[1];
    peopleFinal.push(
      <use
        href={"#people" + randomSvg}
        x={posX + "%"}
        y={posY + "%"}
        key={"person" + i}
      />
    );
  });

  // Add wally
  let posX = randomFromInterval(-50, 50);
  let posY = randomFromInterval(0, 100);
  peopleFinal.push(
    <use href={"#waldo"} x={posX + "%"} y={posY + "%"} key="waldo" />
  );

  peopleFinal.sort(function (a, b) {
    if (a.props.y > b.props.y) {
      return 1;
    }
    return -1;
  });

  return (
    <svg>
      <rect width="100%" height="100%" stroke="green" fill="none" />
      {peopleDefs}
      {peopleFinal}
    </svg>
  );
}

export function encodeSvg(reactElement) {
  return (
    "data:image/svg+xml," +
    escape(ReactDOMServer.renderToStaticMarkup(reactElement))
  );
}

export async function renderMapAsPng() {
  let svg = encodeSvg(renderMapAsSvg());

  let png = await toPng({
    width: 6000,
    height: 6000,
    svg: svg
  }).then((pngUrl) => {
    return pngUrl;
  });

  console.log("renderMapAsPng result", png);

  return png;
}

async function toPng(data) {
  const { width, height, svg } = data;
  const canvas = new OffscreenCanvas(width, height);
  const ctx = canvas.getContext("2d");
  const v = await Canvg.from(ctx, svg, preset);

  // Render only first frame, ignoring animations and mouse.
  await v.render();

  const blob = await canvas.convertToBlob();
  const pngUrl = URL.createObjectURL(blob);

  return pngUrl;
}
